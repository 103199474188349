import React from "react";
import {
  ChevronRightIcon,
  HomeIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useListWorkflows } from "../hooks/api/workflows";
import moment from "moment";
import { Helmet } from "react-helmet";
import { BoltIcon } from "@heroicons/react/24/outline";

export function ListWorkflowsPage() {
  const { data, isLoading, error } = useListWorkflows();
  const pages = [{ name: "Workflows", href: "/workflows", current: true }];

  return (
    <>
      <Helmet>
        <title>Workflows</title>
      </Helmet>

      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      <header className="mt-8">
        <div className="flex justify-between">
          <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
            Workflows
          </h1>

          <div>
            <Link
              to="/workflows/new"
              type="button"
              className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create workflow
            </Link>
          </div>
        </div>
      </header>

      <p className="mt-2 text-sm text-gray-700">
        A workflow is an automation that Codomain Data runs on your CRM. Your
        workflows appear here.
      </p>

      {data?.workflows?.length === 0 && (
        <Link
          to="/workflows/new"
          type="button"
          className="mt-8 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <BoltIcon className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Create your first workflow
          </span>
        </Link>
      )}

      {data?.workflows?.length > 0 && (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Object
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Input Field
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Output Field
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Created
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {data.workflows.map((workflow) => (
                    <tr key={workflow.id}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                        <Link to={`/workflows/${workflow.id}`}>
                          <span className="text-blue-600 underline">
                            {workflow.displayName}
                          </span>
                        </Link>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {workflow.sobject}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {workflow.inputField}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {workflow.outputField}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {moment(workflow.createTime).fromNow()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {/*<div className="text-center pt-32">*/}
      {/*  <svg*/}
      {/*    className="mx-auto h-12 w-12 text-gray-400"*/}
      {/*    fill="none"*/}
      {/*    viewBox="0 0 24 24"*/}
      {/*    stroke="currentColor"*/}
      {/*    aria-hidden="true"*/}
      {/*  >*/}
      {/*    <path*/}
      {/*      vectorEffect="non-scaling-stroke"*/}
      {/*      strokeLinecap="round"*/}
      {/*      strokeLinejoin="round"*/}
      {/*      strokeWidth={2}*/}
      {/*      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"*/}
      {/*    />*/}
      {/*  </svg>*/}
      {/*  isLoading: {isLoading}*/}
      {/*  error: {error}*/}
      {/*  {JSON.stringify(data?.workflows)}*/}
      {/*  <h3 className="mt-2 text-sm font-semibold text-gray-900">*/}
      {/*    No workflows*/}
      {/*  </h3>*/}
      {/*  <p className="mt-1 text-sm text-gray-500">*/}
      {/*    Get started by creating a new workflow.*/}
      {/*  </p>*/}
      {/*  <div className="mt-6">*/}
      {/*    <Link*/}
      {/*      to="/workflows/new"*/}
      {/*      type="button"*/}
      {/*      className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
      {/*    >*/}
      {/*      <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />*/}
      {/*      New Workflow*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
}
