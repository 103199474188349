import axios from "axios";
import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";
import useSWR from "swr";

export interface WorkflowEvent {
  id: string;
  workflowId: string;
  timestamp: string;
  objectId: string;
  inputValue: string;
  scrapedValue: string;
  afterPrompt1Value: string;
  outputValue: boolean;
}

interface ListWorkflowEventsRequest {
  workflowId: string;
  objectId?: string;
}

interface ListWorkflowEventsResponse {
  workflowEvents: WorkflowEvent[];
  nextPageToken: string;
}

async function listWorkflowEvents({ arg }: { arg: ListWorkflowEventsRequest }) {
  const res = await axios.get<ListWorkflowEventsResponse>(
    `${API_BASE_URL}/v1/workflows/${arg.workflowId}/events`,
    {
      headers: {
        Authorization: `Bearer ${getSessionID()}`,
      },
      params: {
        objectId: arg?.objectId,
      },
    },
  );
  return res.data;
}

export function useListWorkflowEvents(req: ListWorkflowEventsRequest) {
  return useSWR(["ListWorkflowEvents", req.workflowId, req.objectId], () =>
    listWorkflowEvents({ arg: req }),
  );
}
