import React, { StrictMode } from "react";
import { createRoutesFromElements, Route, RouterProvider } from "react-router";
import { createBrowserRouter } from "react-router-dom";
import { NotFoundPage } from "./pages/NotFoundPage";
import { AcceptOneTimeLoginPage } from "./pages/AcceptOneTimeLoginPage";
import { SalesforceOAuthAuthorize } from "./pages/SalesforceOAuthAuthorize";
import { SalesforceOAuthCallback } from "./pages/SalesforceOAuthCallback";
import { ListSalesforceConnectionsPage } from "./pages/ListSalesforceConnectionsPage";
import { Sidebar } from "./components/Sidebar";
import { ToastContainer } from "react-toastify";
import { ListWorkflowsPage } from "./pages/ListWorkflowsPage";
import { CreateWorkflowPage } from "./pages/CreateWorkflowPage";
import { HomePage } from "./pages/HomePage";
import { RequireAuth } from "./components/RequireAuth";
import { SignInPage } from "./pages/SignInPage";
import { ViewWorkflowPage } from "./pages/ViewWorkflowPage";
import { CreateWorkflow2Page } from "./pages/CreateWorkflow2Page";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HubspotOAuthCallback } from "./pages/HubspotOAuthCallback";
import { ListHubspotConnectionsPage } from "./pages/ListHubspotConnectionsPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<HomePage />} />

      <Route path="/sign-in" element={<SignInPage />} />

      <Route element={<RequireAuth />}>
        <Route
          path="/one-time-login/:id/accept"
          element={<AcceptOneTimeLoginPage />}
        />

        <Route
          path="/hubspot/oauth/callback"
          element={<HubspotOAuthCallback />}
        />

        <Route
          path="/salesforce/oauth/authorize"
          element={<SalesforceOAuthAuthorize />}
        />

        <Route
          path="/salesforce/oauth/callback"
          element={<SalesforceOAuthCallback />}
        />

        <Route element={<Sidebar />}>
          <Route
            path="/hubspot-connections"
            element={<ListHubspotConnectionsPage />}
          />

          <Route
            path="/salesforce-connections"
            element={<ListSalesforceConnectionsPage />}
          />

          <Route path="/workflows" element={<ListWorkflowsPage />} />
          <Route path="/workflows/new" element={<CreateWorkflowPage />} />
          <Route path="/workflows/new2" element={<CreateWorkflow2Page />} />
          <Route path="/workflows/:id" element={<ViewWorkflowPage />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFoundPage />} />
    </>,
  ),
);

const queryClient = new QueryClient();

export function App() {
  return (
    // todo: cannot use StrictMode because SalesforceOAuthCallback wants to fire a non-idempotent POST on mount

    <>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}
