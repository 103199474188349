import { useMutation, useQuery } from "@tanstack/react-query";
import { useAxios } from "./axios";

export interface HubspotConnection {
  id: string;
  createTime: string;
  displayName: string;
  hubId: string;
  code: string;
}

export interface ListHubspotConnectionsRequest {}

export interface ListHubspotConnectionsResponse {
  hubspotConnections: HubspotConnection[];
}

export interface CreateHubspotConnectionRequest {
  hubspotConnection: Partial<HubspotConnection>;
}

export function useListHubspotConnections(req: ListHubspotConnectionsRequest) {
  const axios = useAxios();
  return useQuery({
    queryKey: ["HubspotConnections"],
    queryFn: async () => {
      const res = await axios.get<ListHubspotConnectionsResponse>(
        "/v1/hubspotConnections",
      );
      return res.data;
    },
  });
}

export function useCreateHubspotConnection() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (req: CreateHubspotConnectionRequest) => {
      const res = await axios.post<HubspotConnection>(
        "/v1/hubspotConnections",
        req.hubspotConnection,
      );

      return res.data;
    },
  });
}
