import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateSalesforceConnection } from "../hooks/api/salesforceConnections";

export function SalesforceOAuthCallback() {
  const [searchParams] = useSearchParams();
  const { trigger, isMutating, data, error } = useCreateSalesforceConnection();
  const navigate = useNavigate();

  const code = searchParams.get("code");

  useEffect(() => {
    trigger({ code: code });
  }, [code]);

  useEffect(() => {
    if (data?.id) {
      navigate("/salesforce-connections");
    }
  }, [data]);

  return (
    <div>
      <div>isMutating: {isMutating}</div>
      <div>data: {JSON.stringify(data)}</div>
      <div>error: {error && error.toString()}</div>
    </div>
  );
}
