import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { useGetUser } from "../hooks/api/users";

// const AIRTABLE_API_KEY =
//   "patLt4QAVEZFbRIeU.4ecb0411d362cae9a0905debe85bd527e660f1d6a7b78a245f2d5482952ba180";
//
// interface WorkflowRequest {
//   id: string;
//   organizationId: string;
//   userId: string;
//   sobject: string;
//   input_field: string;
//   output_field: string;
//   description: string;
// }
//
// async function airtableFetcher(url, { arg }: { arg: WorkflowRequest }) {
//   return await fetch(url, {
//     headers: {
//       Authorization: `Bearer ${AIRTABLE_API_KEY}`,
//     },
//     body: JSON.stringify({ records: [{ fields: arg }] }),
//   });
// }

export function CreateWorkflowPage() {
  // const { trigger } = useSWRMutation(
  //   "https://api.airtable.com/v0/appFKLR6vHDJqpS3F/tbly96iopKNQoPBzm",
  //   airtableFetcher,
  // );

  const { data: me, isLoading, error } = useGetUser({ id: "me" });

  return (
    <>
      <header>
        <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
          Create Workflow
        </h1>
      </header>

      <p className="mt-2 text-sm text-gray-700">Request a new workflow here.</p>

      <form className="mt-8" action="#" method="post">
        <input type="hidden" name="form-name" value="request-new-workflow" />
        <input type="hidden" name="user-id" value={me?.id} />
        <div className="grid grid-cols-1 lg:grid-cols-3">
          <div className="lg:px-4">
            <label
              htmlFor="record-type"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Record Type
            </label>
            <div className="mt-2">
              <input
                name="record-type"
                id="record-type"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Contact"
                aria-describedby="record-type-description"
                required
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500"
              id="record-type-description"
            >
              What kind of record in your CRM should Codomain Data work on?
            </p>
          </div>

          <div className="lg:px-4">
            <label
              htmlFor="input-field"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Input Field
            </label>
            <div className="mt-2">
              <input
                name="input-field"
                id="input-field"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="jobTitle"
                aria-describedby="input-field-description"
                required
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500"
              id="input-field-description"
            >
              What field on your records should Codomain Data read from?
            </p>
          </div>

          <div className="lg:px-4">
            <label
              htmlFor="output-field"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Output Field
            </label>
            <div className="mt-2">
              <input
                name="output-field"
                id="output-field"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="is_medical_doctor__c"
                aria-describedby="output-field-description"
                required
              />
            </div>
            <p
              className="mt-2 text-sm text-gray-500"
              id="output-field-description"
            >
              What field on your records should Codomain Data write to?
            </p>
          </div>
        </div>

        <div className="mt-4 lg:px-4">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="scrape"
                aria-describedby="scrape-description"
                name="scrape"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="scrape" className="font-medium text-gray-900">
                Scrape Website
              </label>
              <p id="scrape-description" className="text-gray-500">
                The input field is a URL, and I want to ask a question about the
                contents of the webpage.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-4 lg:px-4">
          <label
            htmlFor="description"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Workflow Description
          </label>
          <div className="mt-2">
            <textarea
              rows={4}
              name="description"
              id="description"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Is this contact's job title that of a medical doctor? Include general practitioners, but don't include nurses or hospital administrators."
              defaultValue=""
              required
            />

            <p
              className="mt-2 text-sm text-gray-500"
              id="output-field-description"
            >
              Describe how you'd like Codomain Data to determine the correct
              value to put into your Output Field.
            </p>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            to="/workflows"
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save
          </button>
        </div>
      </form>

      {/*  <div className="space-y-12">*/}
      {/*    <div className="border-b border-gray-900/10 pb-12">*/}
      {/*      <h2 className="text-base font-semibold leading-7 text-gray-900">*/}
      {/*        Profile*/}
      {/*      </h2>*/}
      {/*      <p className="mt-1 text-sm leading-6 text-gray-600">*/}
      {/*        This information will be displayed publicly so be careful what you*/}
      {/*        share.*/}
      {/*      </p>*/}

      {/*      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
      {/*        <div className="sm:col-span-4">*/}
      {/*          <label*/}
      {/*            htmlFor="username"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Username*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">*/}
      {/*              <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">*/}
      {/*                workcation.com/*/}
      {/*              </span>*/}
      {/*              <input*/}
      {/*                type="text"*/}
      {/*                name="username"*/}
      {/*                id="username"*/}
      {/*                autoComplete="username"*/}
      {/*                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"*/}
      {/*                placeholder="janesmith"*/}
      {/*              />*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="col-span-full">*/}
      {/*          <label*/}
      {/*            htmlFor="about"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            About*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <textarea*/}
      {/*              id="about"*/}
      {/*              name="about"*/}
      {/*              rows={3}*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*              defaultValue={""}*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*          <p className="mt-3 text-sm leading-6 text-gray-600">*/}
      {/*            Write a few sentences about yourself.*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="col-span-full">*/}
      {/*          <label*/}
      {/*            htmlFor="photo"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Photo*/}
      {/*          </label>*/}
      {/*          <div className="mt-2 flex items-center gap-x-3">*/}
      {/*            <UserCircleIcon*/}
      {/*              className="h-12 w-12 text-gray-300"*/}
      {/*              aria-hidden="true"*/}
      {/*            />*/}
      {/*            <button*/}
      {/*              type="button"*/}
      {/*              className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"*/}
      {/*            >*/}
      {/*              Change*/}
      {/*            </button>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="col-span-full">*/}
      {/*          <label*/}
      {/*            htmlFor="cover-photo"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Cover photo*/}
      {/*          </label>*/}
      {/*          <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">*/}
      {/*            <div className="text-center">*/}
      {/*              <PhotoIcon*/}
      {/*                className="mx-auto h-12 w-12 text-gray-300"*/}
      {/*                aria-hidden="true"*/}
      {/*              />*/}
      {/*              <div className="mt-4 flex text-sm leading-6 text-gray-600">*/}
      {/*                <label*/}
      {/*                  htmlFor="file-upload"*/}
      {/*                  className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"*/}
      {/*                >*/}
      {/*                  <span>Upload a file</span>*/}
      {/*                  <input*/}
      {/*                    id="file-upload"*/}
      {/*                    name="file-upload"*/}
      {/*                    type="file"*/}
      {/*                    className="sr-only"*/}
      {/*                  />*/}
      {/*                </label>*/}
      {/*                <p className="pl-1">or drag and drop</p>*/}
      {/*              </div>*/}
      {/*              <p className="text-xs leading-5 text-gray-600">*/}
      {/*                PNG, JPG, GIF up to 10MB*/}
      {/*              </p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="border-b border-gray-900/10 pb-12">*/}
      {/*      <h2 className="text-base font-semibold leading-7 text-gray-900">*/}
      {/*        Personal Information*/}
      {/*      </h2>*/}
      {/*      <p className="mt-1 text-sm leading-6 text-gray-600">*/}
      {/*        Use a permanent address where you can receive mail.*/}
      {/*      </p>*/}

      {/*      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">*/}
      {/*        <div className="sm:col-span-3">*/}
      {/*          <label*/}
      {/*            htmlFor="first-name"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            First name*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              type="text"*/}
      {/*              name="first-name"*/}
      {/*              id="first-name"*/}
      {/*              autoComplete="given-name"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-3">*/}
      {/*          <label*/}
      {/*            htmlFor="last-name"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Last name*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              type="text"*/}
      {/*              name="last-name"*/}
      {/*              id="last-name"*/}
      {/*              autoComplete="family-name"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-4">*/}
      {/*          <label*/}
      {/*            htmlFor="email"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Email address*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              id="email"*/}
      {/*              name="email"*/}
      {/*              type="email"*/}
      {/*              autoComplete="email"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-3">*/}
      {/*          <label*/}
      {/*            htmlFor="country"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Country*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <select*/}
      {/*              id="country"*/}
      {/*              name="country"*/}
      {/*              autoComplete="country-name"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"*/}
      {/*            >*/}
      {/*              <option>United States</option>*/}
      {/*              <option>Canada</option>*/}
      {/*              <option>Mexico</option>*/}
      {/*            </select>*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="col-span-full">*/}
      {/*          <label*/}
      {/*            htmlFor="street-address"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            Street address*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              type="text"*/}
      {/*              name="street-address"*/}
      {/*              id="street-address"*/}
      {/*              autoComplete="street-address"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-2 sm:col-start-1">*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-2">*/}
      {/*          <label*/}
      {/*            htmlFor="region"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            State / Province*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              type="text"*/}
      {/*              name="region"*/}
      {/*              id="region"*/}
      {/*              autoComplete="address-level1"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}

      {/*        <div className="sm:col-span-2">*/}
      {/*          <label*/}
      {/*            htmlFor="postal-code"*/}
      {/*            className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*          >*/}
      {/*            ZIP / Postal code*/}
      {/*          </label>*/}
      {/*          <div className="mt-2">*/}
      {/*            <input*/}
      {/*              type="text"*/}
      {/*              name="postal-code"*/}
      {/*              id="postal-code"*/}
      {/*              autoComplete="postal-code"*/}
      {/*              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"*/}
      {/*            />*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}

      {/*    <div className="border-b border-gray-900/10 pb-12">*/}
      {/*      <h2 className="text-base font-semibold leading-7 text-gray-900">*/}
      {/*        Notifications*/}
      {/*      </h2>*/}
      {/*      <p className="mt-1 text-sm leading-6 text-gray-600">*/}
      {/*        We'll always let you know about important changes, but you pick*/}
      {/*        what else you want to hear about.*/}
      {/*      </p>*/}

      {/*      <div className="mt-10 space-y-10">*/}
      {/*        <fieldset>*/}
      {/*          <legend className="text-sm font-semibold leading-6 text-gray-900">*/}
      {/*            By Email*/}
      {/*          </legend>*/}
      {/*          <div className="mt-6 space-y-6">*/}
      {/*            <div className="relative flex gap-x-3">*/}
      {/*              <div className="flex h-6 items-center">*/}
      {/*                <input*/}
      {/*                  id="comments"*/}
      {/*                  name="comments"*/}
      {/*                  type="checkbox"*/}
      {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*              <div className="text-sm leading-6">*/}
      {/*                <label*/}
      {/*                  htmlFor="comments"*/}
      {/*                  className="font-medium text-gray-900"*/}
      {/*                >*/}
      {/*                  Comments*/}
      {/*                </label>*/}
      {/*                <p className="text-gray-500">*/}
      {/*                  Get notified when someones posts a comment on a posting.*/}
      {/*                </p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className="relative flex gap-x-3">*/}
      {/*              <div className="flex h-6 items-center">*/}
      {/*                <input*/}
      {/*                  id="candidates"*/}
      {/*                  name="candidates"*/}
      {/*                  type="checkbox"*/}
      {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*              <div className="text-sm leading-6">*/}
      {/*                <label*/}
      {/*                  htmlFor="candidates"*/}
      {/*                  className="font-medium text-gray-900"*/}
      {/*                >*/}
      {/*                  Candidates*/}
      {/*                </label>*/}
      {/*                <p className="text-gray-500">*/}
      {/*                  Get notified when a candidate applies for a job.*/}
      {/*                </p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*            <div className="relative flex gap-x-3">*/}
      {/*              <div className="flex h-6 items-center">*/}
      {/*                <input*/}
      {/*                  id="offers"*/}
      {/*                  name="offers"*/}
      {/*                  type="checkbox"*/}
      {/*                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*                />*/}
      {/*              </div>*/}
      {/*              <div className="text-sm leading-6">*/}
      {/*                <label*/}
      {/*                  htmlFor="offers"*/}
      {/*                  className="font-medium text-gray-900"*/}
      {/*                >*/}
      {/*                  Offers*/}
      {/*                </label>*/}
      {/*                <p className="text-gray-500">*/}
      {/*                  Get notified when a candidate accepts or rejects an*/}
      {/*                  offer.*/}
      {/*                </p>*/}
      {/*              </div>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </fieldset>*/}
      {/*        <fieldset>*/}
      {/*          <legend className="text-sm font-semibold leading-6 text-gray-900">*/}
      {/*            Push Notifications*/}
      {/*          </legend>*/}
      {/*          <p className="mt-1 text-sm leading-6 text-gray-600">*/}
      {/*            These are delivered via SMS to your mobile phone.*/}
      {/*          </p>*/}
      {/*          <div className="mt-6 space-y-6">*/}
      {/*            <div className="flex items-center gap-x-3">*/}
      {/*              <input*/}
      {/*                id="push-everything"*/}
      {/*                name="push-notifications"*/}
      {/*                type="radio"*/}
      {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*              />*/}
      {/*              <label*/}
      {/*                htmlFor="push-everything"*/}
      {/*                className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*              >*/}
      {/*                Everything*/}
      {/*              </label>*/}
      {/*            </div>*/}
      {/*            <div className="flex items-center gap-x-3">*/}
      {/*              <input*/}
      {/*                id="push-email"*/}
      {/*                name="push-notifications"*/}
      {/*                type="radio"*/}
      {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*              />*/}
      {/*              <label*/}
      {/*                htmlFor="push-email"*/}
      {/*                className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*              >*/}
      {/*                Same as email*/}
      {/*              </label>*/}
      {/*            </div>*/}
      {/*            <div className="flex items-center gap-x-3">*/}
      {/*              <input*/}
      {/*                id="push-nothing"*/}
      {/*                name="push-notifications"*/}
      {/*                type="radio"*/}
      {/*                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"*/}
      {/*              />*/}
      {/*              <label*/}
      {/*                htmlFor="push-nothing"*/}
      {/*                className="block text-sm font-medium leading-6 text-gray-900"*/}
      {/*              >*/}
      {/*                No push notifications*/}
      {/*              </label>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </fieldset>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="mt-6 flex items-center justify-end gap-x-6">*/}
      {/*    <button*/}
      {/*      type="button"*/}
      {/*      className="text-sm font-semibold leading-6 text-gray-900"*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </button>*/}
      {/*    <button*/}
      {/*      type="submit"*/}
      {/*      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"*/}
      {/*    >*/}
      {/*      Save*/}
      {/*    </button>*/}
      {/*  </div>*/}
      {/*</form>*/}
    </>
  );
}
