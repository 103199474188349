import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";
import useSWRMutation from "swr/mutation";
import useSWR from "swr";

interface User {
  id: string;
  createTime: string;
  displayName: string;
}

async function getUser(url: string) {
  const res = await fetch(`${API_BASE_URL}${url}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getSessionID()}`,
    },
  });

  if (!res.ok) {
    throw Error(await res.text());
  }

  return (await res.json()) as User;
}

export function useGetUser({ id }: { id: string }) {
  return useSWR(`/v1/users/${id}`, getUser);
}
