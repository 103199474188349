import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";
import useSWRMutation from "swr/mutation";
import axios, { AxiosError } from "axios";

interface UseSignUpResponse {
  session: string;
}

interface UseSignUpRequest {
  googleCredential: string;
}

async function signUp(url: string, { arg }: { arg: UseSignUpRequest }) {
  const res = await axios.post<UseSignUpResponse>(`${API_BASE_URL}${url}`, arg);
  return res.data;
}

export function useSignUp() {
  return useSWRMutation(`/v1/signUp`, signUp);
}
