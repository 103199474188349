import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";
import useSWRMutation from "swr/mutation";

interface UseOneTimeLoginResponse {
  session: string;
}

async function useOneTimeLogin(url: string) {
  const res = await fetch(`${API_BASE_URL}${url}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getSessionID()}`,
    },
  });

  return (await res.json()) as UseOneTimeLoginResponse;
}

export function useUseOneTimeLogin(id: string) {
  return useSWRMutation(`/v1/oneTimeLogins/${id}:use`, useOneTimeLogin);
}
