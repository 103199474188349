import { useGetUser } from "../hooks/api/users";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router";
import { getSessionID } from "../hooks/api/auth";

export function RequireAuth() {
  const navigate = useNavigate();
  if (getSessionID()) {
    return <Outlet />;
  }

  navigate("/sign-in");
}
