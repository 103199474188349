import useSWRMutation from "swr/mutation";
import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";
import useSWR from "swr";
import axios from "axios";

export interface SalesforceConnection {
  id?: string;
  displayName?: string;
  instanceUrl?: string;
  createTime?: string;
  tokenId?: string;
  code?: string;
}

interface ListSalesforceConnectionsRequest {
  pageToken?: string;
}

interface ListSalesforceConnectionsResponse {
  salesforceConnections?: SalesforceConnection[];
  nextPageToken?: string;
}

async function listSalesforceConnections(url: string) {
  const res = await fetch(`${API_BASE_URL}${url}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getSessionID()}`,
    },
  });

  return (await res.json()) as ListSalesforceConnectionsResponse;
}

export function useListSalesforceConnections() {
  return useSWR(`/v1/salesforceConnections`, listSalesforceConnections);
}

async function createSalesforceConnection(
  url: string,
  { arg }: { arg: SalesforceConnection },
) {
  const res = await fetch(`${API_BASE_URL}${url}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getSessionID()}`,
    },
    body: JSON.stringify(arg),
  });

  return (await res.json()) as SalesforceConnection;
}

export function useCreateSalesforceConnection() {
  return useSWRMutation(
    `/v1/salesforceConnections`,
    createSalesforceConnection,
  );
}

interface GetSalesforceConnectionRequest {
  id: string;
}

async function getSalesforceConnection({
  arg,
}: {
  arg: GetSalesforceConnectionRequest;
}) {
  const res = await axios.get<SalesforceConnection>(
    `${API_BASE_URL}/v1/salesforceConnections/${arg.id}`,
    {
      headers: {
        Authorization: `Bearer ${getSessionID()}`,
      },
    },
  );
  return res.data;
}

export function useGetSalesforceConnection(
  req: GetSalesforceConnectionRequest,
) {
  return useSWR(["SalesforceConnections", req.id], () =>
    getSalesforceConnection({ arg: req }),
  );
}
