import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAxios } from "./axios";

export interface HubspotObject {
  id: string;
  hubspotConnectionId: string;
  name: string;
  label: string;
}

interface ListHubspotObjectsRequest {
  hubspotConnectionId: string;
}

interface ListHubspotObjectsResponse {
  hubspotObjects: HubspotObject[];
  nextPageToken: string;
}

export function useListHubspotObjects(
  req: ListHubspotObjectsRequest,
  options?: { enabled?: boolean },
  // options?: UseQueryOptions,
) {
  const axios = useAxios();
  return useQuery({
    ...options,
    queryKey: ["ListHubspotObjects"],
    queryFn: async () => {
      const res = await axios.request<ListHubspotObjectsResponse>({
        method: "get",
        url: `/v1/hubspotConnections/${req.hubspotConnectionId}/objects`,
      });

      return res.data;
    },
  });
}
