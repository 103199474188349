import React, { useEffect } from "react";
import { useMatch, useParams } from "react-router";
import useSWR from "swr";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useUseOneTimeLogin } from "../hooks/api/oneTimeLogins";

export function AcceptOneTimeLoginPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { trigger, data } = useUseOneTimeLogin(id);

  useEffect(() => {
    trigger();
  }, [id]);

  useEffect(() => {
    if (data?.session) {
      localStorage.setItem("session", data.session);
      navigate("/salesforce-connections"); // todo should this be part of a one-time login's info?
    }
  }, [data]);

  return <>loading</>;
}
