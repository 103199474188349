import React from "react";
import { Helmet } from "react-helmet";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useListHubspotConnections } from "../hooks/api/hubspotConnections";
import { HUBSPOT_OAUTH_CLIENT_ID, HUBSPOT_OAUTH_REDIRECT_URI } from "../config";

const OAUTH_AUTHORIZE_URL = `https://app.hubspot.com/oauth/authorize?client_id=${HUBSPOT_OAUTH_CLIENT_ID}&redirect_uri=${HUBSPOT_OAUTH_REDIRECT_URI}&scope=oauth&optional_scope=crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.marketing_events.read%20crm.objects.marketing_events.write%20crm.schemas.custom.read%20crm.objects.custom.read%20crm.objects.custom.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.objects.feedback_submissions.read%20crm.lists.write%20crm.objects.companies.read%20crm.objects.deals.read%20crm.objects.deals.write%20crm.schemas.companies.read%20crm.schemas.companies.write%20crm.schemas.contacts.write%20crm.schemas.deals.read%20crm.schemas.deals.write%20crm.objects.owners.read%20crm.objects.quotes.write%20crm.objects.quotes.read%20crm.schemas.quotes.read%20crm.objects.line_items.read%20crm.objects.line_items.write%20crm.schemas.line_items.read%20crm.objects.goals.read`;

export function ListHubspotConnectionsPage() {
  const { data } = useListHubspotConnections({});

  const pages = [
    {
      name: "Hubspot Connections",
      href: "/hubspot-connections",
      current: true,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Hubspot Connections</title>
      </Helmet>

      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      <div className="mt-8 sm:flex sm:items-center">
        <div className="sm:flex-auto px-4 py-5 sm:p-6">
          <h2 className="font-semibold text-xl">HubSpot Connections</h2>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <a
            href={OAUTH_AUTHORIZE_URL}
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Connect to HubSpot
          </a>
        </div>
      </div>

      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Created At
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Portal / Hub ID
                  </th>
                  {/*<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">*/}
                  {/*  <span className="sr-only">Edit</span>*/}
                  {/*</th>*/}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {(data?.hubspotConnections ?? []).map((hubspotConnection) => (
                  <tr key={hubspotConnection.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                      {hubspotConnection.displayName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {hubspotConnection.createTime}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {hubspotConnection.hubId}
                    </td>
                    {/*<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">*/}
                    {/*  <a*/}
                    {/*    href="#"*/}
                    {/*    className="text-indigo-600 hover:text-indigo-900"*/}
                    {/*  >*/}
                    {/*    Edit*/}
                    {/*    <span className="sr-only">*/}
                    {/*      , {salesforceConnection.id}*/}
                    {/*    </span>*/}
                    {/*  </a>*/}
                    {/*</td>*/}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
