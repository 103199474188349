import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://e15b63e2e953be15f5d82898b050c5dd@o4505847296557056.ingest.sentry.io/4505847298588672",
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("react-root"));
root.render(<App />);
