import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAxios } from "./axios";

export interface SalesforceField {
  id: string;
  salesforceConnectionId: string;
  objectName: string;
  name: string;
  label: string;
}

interface ListSalesforceFieldsRequest {
  salesforceConnectionId: string;
  objectName: string;
  kind: "BOOLEAN" | "STRING";
}

interface ListSalesforceFieldsResponse {
  salesforceFields: SalesforceField[];
  nextPageToken: string;
}

export function useListSalesforceFields(
  req: ListSalesforceFieldsRequest,
  options?: { enabled?: boolean },
  // options?: UseQueryOptions,
) {
  const axios = useAxios();
  return useQuery({
    ...options,
    queryKey: ["ListSalesforceFields", req.objectName, req.kind],
    queryFn: async () => {
      const res = await axios.request<ListSalesforceFieldsResponse>({
        method: "get",
        url: `/v1/salesforceConnections/${req.salesforceConnectionId}/objects/${req.objectName}/fields`,
        params: {
          kind: req.kind,
        },
      });

      return res.data;
    },
  });
}
