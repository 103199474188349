import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCreateHubspotConnection } from "../hooks/api/hubspotConnections";

export function HubspotOAuthCallback() {
  const [searchParams] = useSearchParams();
  const createHubspotConnection = useCreateHubspotConnection();
  const navigate = useNavigate();
  const code = searchParams.get("code");

  useEffect(() => {
    createHubspotConnection.mutate({
      hubspotConnection: {
        code,
      },
    });
  }, [code]);

  useEffect(() => {
    if (createHubspotConnection.isSuccess) {
      navigate("/hubspot-connections");
    }
  }, [createHubspotConnection.isSuccess]);

  return (
    <div>
      <div>{code}</div>
      <div>{JSON.stringify(createHubspotConnection.data)}</div>
    </div>
  );
}
