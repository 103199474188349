import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { useAxios } from "./axios";

export interface SalesforceObject {
  id: string;
  salesforceConnectionId: string;
  name: string;
  label: string;
}

interface ListSalesforceObjectsRequest {
  salesforceConnectionId: string;
}

interface ListSalesforceObjectsResponse {
  salesforceObjects: SalesforceObject[];
  nextPageToken: string;
}

export function useListSalesforceObjects(
  req: ListSalesforceObjectsRequest,
  options?: { enabled?: boolean },
  // options?: UseQueryOptions,
) {
  const axios = useAxios();
  return useQuery({
    ...options,
    queryKey: ["ListSalesforceObjects"],
    queryFn: async () => {
      const res = await axios.request<ListSalesforceObjectsResponse>({
        method: "get",
        url: `/v1/salesforceConnections/${req.salesforceConnectionId}/objects`,
      });

      return res.data;
    },
  });
}
