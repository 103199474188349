import React from "react";
import { Helmet } from "react-helmet";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { useCreateWorkflow } from "../hooks/api/workflows";
import {
  SalesforceConnection,
  useListSalesforceConnections,
} from "../hooks/api/salesforceConnections";
import {
  SalesforceObject,
  useListSalesforceObjects,
} from "../hooks/api/salesforceObjects";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import {
  SalesforceField,
  useListSalesforceFields,
} from "../hooks/api/salesforceFields";
import {
  HubspotConnection,
  useListHubspotConnections,
} from "../hooks/api/hubspotConnections";
import {
  HubspotObject,
  useListHubspotObjects,
} from "../hooks/api/hubspotObjects";
import { HubspotField, useListHubspotFields } from "../hooks/api/hubspotFields";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

interface FormData {
  displayName: string;
  scrape: "yes" | "no";
  prompt1: string;
  prompt2SystemPrompt: string;
  prompt2FunctionName: string;
  prompt2FunctionDescription: string;
  prompt2ArgumentName: string;
  prompt2ArgumentDescription: string;
  backfill: "yes" | "no";
}

export function CreateWorkflow2Page() {
  const { register, handleSubmit, control } = useForm<FormData>();
  const { trigger: createWorkflow } = useCreateWorkflow();
  const navigate = useNavigate();

  const [selectedCRMConnection, setSelectedCRMConnection] =
    useState<CRMConnection | null>(null);

  const [selectedObject, setSelectedObject] = useState<CRMObject | undefined>(
    undefined,
  );

  const [selectedInputField, setSelectedInputField] = useState<
    CRMField | undefined
  >(undefined);

  const [selectedOutputField, setSelectedOutputField] = useState<
    CRMField | undefined
  >(undefined);

  const onSubmit = async (data: FormData) => {
    const workflow = await createWorkflow({
      workflow: {
        displayName: data.displayName,
        salesforceConnectionId:
          selectedCRMConnection?.type === "salesforce"
            ? selectedCRMConnection.salesforceConnection.id
            : "",
        hubspotConnectionId:
          selectedCRMConnection?.type === "hubspot"
            ? selectedCRMConnection.hubspotConnection.id
            : "",
        sobject:
          selectedObject.type === "salesforce"
            ? selectedObject.salesforceObject.name
            : selectedObject.hubspotObject.name,
        inputField:
          selectedInputField.type === "salesforce"
            ? selectedInputField.salesforceField.name
            : selectedInputField.hubspotField.name,
        outputField:
          selectedOutputField.type === "salesforce"
            ? selectedOutputField.salesforceField.name
            : selectedOutputField.hubspotField.name,
        scrape: data.scrape === "yes",
        prompt1: data.prompt1,
        prompt2: JSON.stringify({
          system_prompt: data.prompt2SystemPrompt,
          function_name: data.prompt2FunctionName,
          function_description: data.prompt2FunctionDescription,
          arg_name: data.prompt2ArgumentName,
          arg_description: data.prompt2ArgumentDescription,
        }),
      },
      backfill: data.backfill === "yes",
    });

    navigate(`/workflows/${workflow.id}`);
  };

  const pages = [
    { name: "Workflows", href: "/workflows", current: false },
    { name: "Create Workflow", href: `/workflows/new`, current: true },
  ];

  return (
    <>
      <Helmet>
        <title>Create Workflow</title>
      </Helmet>

      <nav className="flex" aria-label="Breadcrumb">
        <ol role="list" className="flex items-center space-x-4">
          <li>
            <div>
              <a href="/" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
          {pages.map((page) => (
            <li key={page.name}>
              <div className="flex items-center">
                <ChevronRightIcon
                  className="h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <Link
                  to={page.href}
                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </Link>
              </div>
            </li>
          ))}
        </ol>
      </nav>

      <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-xl">Workflow Name</h2>

            <div className="mt-4 max-w-2xl">
              <label
                htmlFor="displayName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Display Name
              </label>
              <div className="mt-2">
                <input
                  id="displayName"
                  {...register("displayName", { required: true })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="Is Vertical SaaS"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                A name for your workflow. Not displayed in your CRM.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-xl">CRM Settings</h2>
            <div className="mt-4 max-w-2xl">
              <CRMConnectionSelector
                value={selectedCRMConnection}
                onChange={setSelectedCRMConnection}
              />

              <p className="mt-2 text-sm text-gray-500">
                What CRM do you want to use?
              </p>
            </div>

            <div className="mt-4 max-w-2xl">
              <CRMObjectSelector
                crmConnection={selectedCRMConnection}
                value={selectedObject}
                onChange={setSelectedObject}
              />

              <p className="mt-2 text-sm text-gray-500">
                What kind of record in your CRM should Codomain Data work on?
              </p>
            </div>

            <div className="mt-4 max-w-2xl">
              <CRMFieldSelector
                isInputField={true}
                crmObject={selectedObject}
                value={selectedInputField}
                onChange={setSelectedInputField}
              />

              <p className="mt-2 text-sm text-gray-500">
                What field on your records should Codomain Data read from?
              </p>
            </div>

            <div className="mt-4 max-w-2xl">
              <CRMFieldSelector
                isInputField={false}
                crmObject={selectedObject}
                value={selectedOutputField}
                onChange={setSelectedOutputField}
              />

              <p className="mt-2 text-sm text-gray-500">
                What field on your records should Codomain Data write to?
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-xl">Scraping Settings</h2>

            <fieldset className="mt-4">
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="scrape-yes"
                      {...register("scrape")}
                      type="radio"
                      value="yes"
                      defaultChecked
                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="scrape-yes"
                      className="font-medium text-gray-900"
                    >
                      Scrape
                    </label>
                    <p className="text-gray-500">
                      The input field is a URL. Scrape the contents of the
                      webpage, and pass the webpage text to the AI.
                    </p>
                  </div>
                </div>

                <div className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        {...register("scrape")}
                        id="scrape-no"
                        type="radio"
                        value="no"
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="scrape-no"
                        className="font-medium text-gray-900"
                      >
                        Do not scrape
                      </label>
                      <p className="text-gray-500">
                        Do not scrape the input field.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-xl">Prompt Settings</h2>

            <div className="mt-4 max-w-4xl">
              <label
                htmlFor="prompt1"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                First-Pass Prompt
              </label>
              <div className="mt-2">
                <textarea
                  id="prompt1"
                  {...register("prompt1", { required: true })}
                  rows={4}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder={`You are a market analyst. You will be given text from a company's website. Is the company a vertical SaaS business?\nA vertical SaaS business sells a software package, as opposed to software consulting.\nThink step by step.`}
                />

                <p
                  className="mt-2 text-sm text-gray-500"
                  id="output-field-description"
                >
                  In the first-pass prompt, you should have the AI think
                  step-by-step about how to figure out the answer to your
                  question.
                </p>
              </div>
            </div>

            <div className="mt-4 max-w-4xl">
              <label
                htmlFor="prompt2SystemPrompt"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Second-Pass Prompt
              </label>
              <div className="mt-2">
                <textarea
                  id="prompt2SystemPrompt"
                  {...register("prompt2SystemPrompt", { required: true })}
                  rows={4}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder={`You are a market analyst. You will be given text an analysis of whether a company is vertical SaaS. Call \`is_vertical_saas\` based on whether the company a vertical SaaS business.\nA vertical SaaS business sells a software package, as opposed to software consulting.`}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                In the second pass, the AI will read its own answer from the
                first pass, and extract it into a single function call. If you
                gave the AI some business context in the first prompt, you
                should repeat it here again.
              </p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="prompt2FunctionName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Second-Pass Function Name
              </label>
              <div className="mt-2">
                <input
                  id="prompt2FunctionName"
                  {...register("prompt2FunctionName", { required: true })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="is_vertical_saas"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                The second-pass function name should be a good "developer" name
                for the desired output.
              </p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="prompt2FunctionDescription"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Second-Pass Function Description
              </label>
              <div className="mt-2">
                <input
                  id="prompt2FunctionDescription"
                  {...register("prompt2FunctionDescription", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="Whether the company is vertical SaaS."
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Document what the second-pass function does.
              </p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="prompt2ArgumentName"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Second-Pass Argument Name
              </label>
              <div className="mt-2">
                <input
                  id="prompt2ArgumentName"
                  {...register("prompt2ArgumentName", { required: true })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="is_vertical_saas"
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                The second-pass argument name should be a good "developer" name
                for the desired output.
              </p>
            </div>

            <div className="mt-4">
              <label
                htmlFor="prompt2ArgumentDescription"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Second-Pass Argument Description
              </label>
              <div className="mt-2">
                <input
                  id="prompt2ArgumentDescription"
                  {...register("prompt2ArgumentDescription", {
                    required: true,
                  })}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                  placeholder="`true` if the company is vertical SaaS, or `false` if it's unclear, it isn't a company at all, or is a non-vertical-SaaS company."
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">
                Document what the second-pass argument should be. What counts as
                a "true" / "checked" result, and what counts as a "false" /
                "unchecked" result?
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-xl">Backfill Settings</h2>
            <fieldset className="mt-4">
              <div className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex h-6 items-center">
                    <input
                      id="backfill-yes"
                      {...register("backfill")}
                      type="radio"
                      value="yes"
                      defaultChecked
                      className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                    />
                  </div>
                  <div className="ml-3 text-sm leading-6">
                    <label
                      htmlFor="backfill-yes"
                      className="font-medium text-gray-900"
                    >
                      Backfill
                    </label>
                    <p className="text-gray-500">
                      Run the workflow on all existing data as well as any new
                      records that are created or updated.
                    </p>
                  </div>
                </div>

                <div className="space-y-5">
                  <div className="relative flex items-start">
                    <div className="flex h-6 items-center">
                      <input
                        {...register("backfill")}
                        id="backfill-no"
                        type="radio"
                        value="no"
                        className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor="backfill-no"
                        className="font-medium text-gray-900"
                      >
                        No Backfill
                      </label>
                      <p className="text-gray-500">
                        Only run the workflow on new records that are created or
                        updated.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <Link
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
            to="/workflows"
          >
            Cancel
          </Link>
          <button
            type="submit"
            className="rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Create Workflow
          </button>
        </div>
      </form>
    </>
  );
}

type CRMConnection =
  | { type: "salesforce"; salesforceConnection: SalesforceConnection }
  | { type: "hubspot"; hubspotConnection: HubspotConnection };

function CRMConnectionSelector({
  value,
  onChange,
}: {
  value: CRMConnection | null;
  onChange: (CRMConnection) => void;
}) {
  const { data: salesforceConnections } = useListSalesforceConnections();
  const { data: hubspotConnections } = useListHubspotConnections({});

  const crmConnections: CRMConnection[] = [
    ...(salesforceConnections?.salesforceConnections?.map(
      (salesforceConnection) => ({
        type: "salesforce" as const,
        salesforceConnection,
      }),
    ) ?? []),
    ...(hubspotConnections?.hubspotConnections?.map((hubspotConnection) => ({
      type: "hubspot" as const,
      hubspotConnection,
    })) ?? []),
  ];

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label
            htmlFor="object"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            CRM Connection
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6">
              <span className="inline-flex w-full truncate">
                {value ? (
                  <>
                    <span className="truncate">
                      {value.type === "salesforce"
                        ? value.salesforceConnection.displayName
                        : value.hubspotConnection.displayName}
                    </span>
                    {/*<span className="ml-2 truncate text-gray-500">*/}
                    {/*  {selectedCRMConnection}*/}
                    {/*</span>*/}
                  </>
                ) : (
                  "None selected"
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {crmConnections.map((crmConnection) => (
                  <Listbox.Option
                    key={
                      crmConnection.type === "salesforce"
                        ? crmConnection.salesforceConnection.id
                        : crmConnection.hubspotConnection.id
                    }
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={crmConnection}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate",
                            )}
                          >
                            {crmConnection.type === "salesforce"
                              ? crmConnection.salesforceConnection.displayName
                              : crmConnection.hubspotConnection.displayName}
                          </span>
                          <span
                            className={classNames(
                              active ? "text-blue-200" : "text-gray-500",
                              "ml-2 truncate",
                            )}
                          >
                            {crmConnection.type === "salesforce"
                              ? crmConnection.salesforceConnection.instanceUrl
                              : crmConnection.hubspotConnection.hubId}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

type CRMObject =
  | { type: "salesforce"; salesforceObject: SalesforceObject }
  | { type: "hubspot"; hubspotObject: HubspotObject };

function CRMObjectSelector({
  crmConnection,
  value,
  onChange,
}: {
  crmConnection?: CRMConnection;
  value: CRMObject;
  onChange: (CRMObject) => void;
}) {
  const { data: salesforceObjects } = useListSalesforceObjects(
    {
      salesforceConnectionId:
        crmConnection?.type === "salesforce" &&
        crmConnection.salesforceConnection.id,
    },
    {
      enabled: !!crmConnection && crmConnection.type === "salesforce",
    },
  );

  const { data: hubspotObjects } = useListHubspotObjects(
    {
      hubspotConnectionId:
        crmConnection?.type === "hubspot" && crmConnection.hubspotConnection.id,
    },
    {
      enabled: !!crmConnection && crmConnection.type === "hubspot",
    },
  );

  const crmObjects: CRMObject[] = crmConnection
    ? crmConnection.type === "salesforce"
      ? salesforceObjects?.salesforceObjects?.map((salesforceObject) => ({
          type: "salesforce" as const,
          salesforceObject,
        })) ?? []
      : hubspotObjects?.hubspotObjects?.map((hubspotObject) => ({
          type: "hubspot" as const,
          hubspotObject,
        })) ?? []
    : [];

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label
            htmlFor="object"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Object
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6">
              <span className="inline-flex w-full truncate">
                {value ? (
                  <>
                    <span className="truncate">
                      {value.type === "salesforce"
                        ? value.salesforceObject.label
                        : value.hubspotObject.label}
                    </span>
                    {/*<span className="ml-2 truncate text-gray-500">*/}
                    {/*  {selectedCRMConnection}*/}
                    {/*</span>*/}
                  </>
                ) : (
                  "None selected"
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {crmObjects.map((crmObject) => (
                  <Listbox.Option
                    key={
                      crmObject.type === "salesforce"
                        ? crmObject.salesforceObject.id
                        : crmObject.hubspotObject.id
                    }
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={crmObject}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate",
                            )}
                          >
                            {crmObject.type === "salesforce"
                              ? crmObject.salesforceObject.label
                              : crmObject.hubspotObject.label}
                          </span>
                          <span
                            className={classNames(
                              active ? "text-blue-200" : "text-gray-500",
                              "ml-2 truncate",
                            )}
                          >
                            {crmObject.type === "salesforce"
                              ? crmObject.salesforceObject.name
                              : crmObject.hubspotObject.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

type CRMField =
  | { type: "salesforce"; salesforceField: SalesforceField }
  | { type: "hubspot"; hubspotField: HubspotField };

function CRMFieldSelector({
  isInputField,
  crmObject,
  value,
  onChange,
}: {
  isInputField: boolean;
  crmObject?: CRMObject;
  value: CRMField;
  onChange: (CRMField) => void;
}) {
  const { data: salesforceFields } = useListSalesforceFields(
    {
      salesforceConnectionId:
        crmObject?.type === "salesforce" &&
        crmObject.salesforceObject.salesforceConnectionId,
      kind: isInputField ? "STRING" : "BOOLEAN",
      objectName:
        crmObject?.type === "salesforce" && crmObject.salesforceObject.name,
    },
    {
      enabled: !!crmObject && crmObject.type === "salesforce",
    },
  );

  const { data: hubspotFields } = useListHubspotFields(
    {
      hubspotConnectionId:
        crmObject?.type === "hubspot" &&
        crmObject.hubspotObject.hubspotConnectionId,
      kind: isInputField
        ? "HUBSPOT_FIELD_KIND_STRING"
        : "HUBSPOT_FIELD_KIND_BOOLEAN",
      objectName: crmObject?.type === "hubspot" && crmObject.hubspotObject.name,
    },
    {
      enabled: !!crmObject && crmObject.type === "hubspot",
    },
  );

  const crmFields: CRMField[] = crmObject
    ? crmObject.type === "salesforce"
      ? salesforceFields?.salesforceFields?.map((salesforceField) => ({
          type: "salesforce" as const,
          salesforceField,
        })) ?? []
      : hubspotFields?.hubspotFields?.map((hubspotField) => ({
          type: "hubspot" as const,
          hubspotField,
        })) ?? []
    : [];

  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label
            htmlFor="object"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            {isInputField ? "Input Field" : "Output Field"}
          </Listbox.Label>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 sm:text-sm sm:leading-6">
              <span className="inline-flex w-full truncate">
                {value ? (
                  <>
                    <span className="truncate">
                      {value.type === "salesforce"
                        ? value.salesforceField.label
                        : value.hubspotField.label}
                    </span>
                    {/*<span className="ml-2 truncate text-gray-500">*/}
                    {/*  {selectedCRMConnection}*/}
                    {/*</span>*/}
                  </>
                ) : (
                  "None selected"
                )}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {crmFields.map((crmField) => (
                  <Listbox.Option
                    key={
                      crmField.type === "salesforce"
                        ? crmField.salesforceField.id
                        : crmField.hubspotField.id
                    }
                    className={({ active }) =>
                      classNames(
                        active ? "bg-blue-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9",
                      )
                    }
                    value={crmField}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex">
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "truncate",
                            )}
                          >
                            {crmField.type === "salesforce"
                              ? crmField.salesforceField.label
                              : crmField.hubspotField.label}
                          </span>
                          <span
                            className={classNames(
                              active ? "text-blue-200" : "text-gray-500",
                              "ml-2 truncate",
                            )}
                          >
                            {crmField.type === "salesforce"
                              ? crmField.salesforceField.name
                              : crmField.hubspotField.name}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4",
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
