import axios from "axios";
import { API_BASE_URL } from "../../config";
import { getSessionID } from "./auth";

export function useAxios() {
  return axios.create({
    baseURL: API_BASE_URL,
    headers: {
      Authorization: `Bearer ${getSessionID()}`,
    },
  });
}
