import React, { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useGetWorkflow } from "../hooks/api/workflows";
import { Helmet } from "react-helmet";
import moment from "moment";
import {
  SalesforceConnection,
  useGetSalesforceConnection,
} from "../hooks/api/salesforceConnections";
import {
  ChevronDownIcon,
  HomeIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import {
  useListWorkflowEvents,
  WorkflowEvent,
} from "../hooks/api/workflowEvents";
import clsx from "clsx";

export function ViewWorkflowPage() {
  const { id } = useParams<{ id: string }>();
  const { data } = useGetWorkflow({ id });
  const { data: salesforceConnection } = useGetSalesforceConnection({
    id: data?.salesforceConnectionId,
  });

  const [searchObjectID, setSearchObjectID] = useState("");

  const { data: workflowEvents } = useListWorkflowEvents({
    workflowId: id,
    objectId: searchObjectID,
  });

  const promptData = JSON.parse(data?.prompt2 ?? "{}");
  const pages = [
    { name: "Workflows", href: "/workflows", current: false },
    { name: data?.displayName, href: `/workflows/${data?.id}`, current: true },
  ];

  return (
    <>
      <Helmet>
        <title>{data?.displayName}</title>
      </Helmet>

      {data && (
        <>
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li>
                <div>
                  <a href="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </a>
                </div>
              </li>
              {pages.map((page) => (
                <li key={page.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <Link
                      to={page.href}
                      className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                      aria-current={page.current ? "page" : undefined}
                    >
                      {page.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </nav>

          <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
            <div className="px-4 py-5 sm:p-6">
              <h2 className="font-semibold text-xl">Workflow Settings</h2>

              <div className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4">
                <div className="flex flex-col gap-4 bg-white px-4 py-10 sm:px-6 xl:px-8">
                  <div>
                    <div className="font-semibold">Object</div>
                    <div>{data.sobject}</div>
                  </div>
                  <div>
                    <div className="font-semibold">Input Field</div>
                    <div>{data.inputField}</div>
                  </div>
                  <div>
                    <div className="font-semibold">Output Field</div>
                    <div>{data.outputField}</div>
                  </div>
                  <div>
                    <div className="font-semibold">Website Scraping</div>
                    <div>
                      {data.scrape ? "Scraping Enabled" : "No scraping."}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 bg-white px-4 py-10 sm:px-6 xl:px-8">
                  <div>
                    <div className="font-semibold">First-Pass Prompt</div>
                    <div>{data?.prompt1}</div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 bg-white px-4 py-10 sm:px-6 xl:px-8">
                  <div>
                    <div className="font-semibold">Second-Pass Prompt</div>
                    <div>{promptData["system_prompt"]}</div>
                  </div>

                  <div>
                    <div className="font-semibold">
                      Second-Pass Function Name
                    </div>
                    <div>{promptData["function_name"]}</div>
                  </div>

                  <div>
                    <div className="font-semibold">
                      Second-Pass Function Description
                    </div>
                    <div>{promptData["function_description"]}</div>
                  </div>

                  <div>
                    <div className="font-semibold">
                      Second-Pass Argument Name
                    </div>
                    <div>{promptData["arg_name"]}</div>
                  </div>
                  <div>
                    <div className="font-semibold">
                      Second-Pass Argument Description
                    </div>
                    <div>{promptData["arg_description"]}</div>
                  </div>
                </div>

                <div className="flex flex-col gap-4 bg-white px-4 py-10 sm:px-6 xl:px-8">
                  <div>
                    <div className="font-semibold">Created</div>
                    <div>{moment(data.createTime).fromNow()}</div>
                  </div>
                  <div>
                    <div className="font-semibold">Last Fetch from CRM</div>
                    <div>{moment(data.incrementalSyncTime).fromNow()}</div>
                  </div>
                  <div>
                    <div className="font-semibold">CRM Connection</div>
                    <div>{salesforceConnection?.instanceUrl}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="mt-8 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="font-semibold text-xl">Event Log</h2>

          <div className="relative mt-2 rounded-md shadow-sm max-w-2xl">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              type="text"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              placeholder="Filter by object ID"
              value={searchObjectID}
              onChange={(e) => setSearchObjectID(e.target.value)}
            />
          </div>

          <div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th scope="col" className="px-3 py-3.5"></th>

                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Object ID
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Processed
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Input Field Value
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Scraped Value
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          First-Pass Result
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Output Field Value
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {workflowEvents?.workflowEvents.map((workflowEvent) => (
                        <WorkflowEventLogTableRow
                          key={workflowEvent.id}
                          object={data?.sobject}
                          salesforceConnection={salesforceConnection}
                          workflowEvent={workflowEvent}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function WorkflowEventLogTableRow({
  object,
  workflowEvent,
  salesforceConnection,
}: {
  object: string;
  salesforceConnection: SalesforceConnection;
  workflowEvent: WorkflowEvent;
}) {
  const [open, setOpen] = useState(false);

  return (
    <tr>
      <td className="px-3 py-4 cursor-pointer" onClick={() => setOpen(!open)}>
        {open ? (
          <ChevronDownIcon className="h-4 w-4" />
        ) : (
          <ChevronRightIcon className="h-4 w-4" />
        )}
      </td>
      <>
        <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 sm:pl-0">
          <Link
            to={`${salesforceConnection?.instanceUrl}/lightning/r/${object}/${workflowEvent.objectId}/view`}
          >
            <span className="text-blue-600 underline">
              {workflowEvent.objectId}
            </span>
          </Link>
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {moment(workflowEvent.timestamp).fromNow()}
        </td>
        <td
          className={clsx(
            "px-3 py-4 text-sm text-gray-500 text-ellipsis overflow-hidden max-w-xs",
            !open && "whitespace-nowrap",
          )}
        >
          {workflowEvent.inputValue}
        </td>
        <td
          className={clsx(
            "px-3 py-4 text-sm text-gray-500 text-ellipsis overflow-hidden max-w-xs",
            !open && "whitespace-nowrap",
          )}
        >
          <WorkflowCellText open={open} text={workflowEvent.scrapedValue} />
        </td>
        <td
          className={clsx(
            "px-3 py-4 text-sm text-gray-500 text-ellipsis overflow-hidden max-w-xs",
            !open && "whitespace-nowrap",
          )}
        >
          <WorkflowCellText
            open={open}
            text={workflowEvent.afterPrompt1Value}
          />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
            checked={workflowEvent.outputValue}
          />
        </td>
      </>
    </tr>
  );
}

function WorkflowCellText({ text, open }: { text: string; open: boolean }) {
  if (open) {
    return text.split("\n").map((s, i) => <p key={i}>{s}</p>);
  }
  return text;
}
