import React, { useCallback, useState } from "react";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import * as jose from "jose";
import { useSignUp } from "../hooks/api/signUp";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { GOOGLE_OAUTH_CLIENT_ID } from "../config";

export function SignInPage() {
  const navigate = useNavigate();
  const signUp = useSignUp();
  const [isPersonalEmailError, setIsPersonalEmailError] = useState(false);

  const handleLoginSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      try {
        const { session } = await signUp.trigger({
          googleCredential: credentialResponse.credential,
        });
        localStorage.setItem("session", session);
        navigate("/salesforce-connections");
      } catch (e) {
        if (e instanceof AxiosError) {
          if (e.response.status === 403) {
            if (
              e.response.data?.message ===
              "GOOGLE_CREDENTIAL_LACKS_HOSTED_DOMAIN"
            ) {
              setIsPersonalEmailError(true);
            }
          }
        }
      }
    },
    [],
  );

  return (
    <div className="h-full bg-gray-50">
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src="/logo-color.svg"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="m-auto">
              <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
                <GoogleLogin
                  size="large"
                  logo_alignment="center"
                  onSuccess={handleLoginSuccess}
                />
              </GoogleOAuthProvider>
            </div>

            {isPersonalEmailError && (
              <p className="mt-2 text-sm text-red-600" id="email-error">
                You must use a corporate Google login.
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
