import useSWR from "swr";
import axios from "axios";
import { getSessionID } from "./auth";
import { API_BASE_URL } from "../../config";
import useSWRMutation from "swr/mutation";

interface Workflow {
  id: string;
  createTime: string;
  displayName: string;
  hubspotConnectionId: string;
  salesforceConnectionId: string;
  sobject: string;
  inputField: string;
  outputField: string;
  scrape: boolean;
  prompt1: string;
  prompt2: string;
  incrementalSyncTime: string;
}

interface ListWorkflowsResponse {
  workflows: Workflow[];
  nextPageToken: string;
}

async function listWorkflows() {
  const res = await axios.get<ListWorkflowsResponse>(
    `${API_BASE_URL}/v1/workflows`,
    {
      headers: {
        Authorization: `Bearer ${getSessionID()}`,
      },
    },
  );
  return res.data;
}

export function useListWorkflows() {
  return useSWR(["Workflows"], listWorkflows);
}

interface GetWorkflowRequest {
  id: string;
}

async function getWorkflow({ arg }: { arg: GetWorkflowRequest }) {
  const res = await axios.get<Workflow>(
    `${API_BASE_URL}/v1/workflows/${arg.id}`,
    {
      headers: {
        Authorization: `Bearer ${getSessionID()}`,
      },
    },
  );
  return res.data;
}

export function useGetWorkflow(req: GetWorkflowRequest) {
  return useSWR(["Workflows", req.id], () => getWorkflow({ arg: req }));
}

interface CreateWorkflowRequest {
  workflow: Partial<Workflow>;
  backfill: boolean;
}

async function createWorkflow(_, { arg }: { arg: CreateWorkflowRequest }) {
  const res = await axios.post<Workflow>(
    `${API_BASE_URL}/v1/workflows`,
    arg.workflow,
    {
      headers: {
        Authorization: `Bearer ${getSessionID()}`,
      },
      params: {
        backfill: arg.backfill.toString(),
      },
    },
  );
  return res.data;
}

export function useCreateWorkflow() {
  return useSWRMutation(["CreateWorkflow"], createWorkflow);
}
