import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { SALESFORCE_APP_ID, SALESFORCE_REDIRECT_URI } from "../config";

// const REDIRECT_URI = "http://localhost:8080/salesforce/oauth/callback";
// const SALESFORCE_APP_ID = SALES
// "3MVG9VTfpJmxg1yhRMDbEqqNvePQRi.QvtORgDdnRJ3YOB4ddD8MqQ7lHGWZ4SrKF5i_.p9W1h4Qiy58MWx04";
const SALESFORCE_AUTHORIZE_BASE_URL =
  "https://login.salesforce.com/services/oauth2/authorize";

const SALESFORCE_AUTHORIZE_URL = `${SALESFORCE_AUTHORIZE_BASE_URL}?response_type=code&client_id=${SALESFORCE_APP_ID}&redirect_uri=${SALESFORCE_REDIRECT_URI}`;

export function SalesforceOAuthAuthorize() {
  return (
    <div className="flex h-full bg-gray-200">
      <div className="max-w-2xl m-auto">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Connect to Salesforce
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                Click the button below to connect your Salesforce to Codomain
                Data.
              </p>
            </div>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                You'll be redirected to a Salesforce webpage asking you to
                authorize Codomain Data to access your Salesforce data.
              </p>
            </div>
            <div className="mt-5">
              <a
                href={SALESFORCE_AUTHORIZE_URL}
                type="button"
                className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              >
                Connect Salesforce
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
